import React from 'react'
import GoogleMapReact from 'google-map-react';
import Marker from './marker';

const defaultProps = {
  center: {
    lat: 54.0249078,
    lng: -6.8566822,
  },
  zoom: 14,
}

const irelandMap = () => (
  <div style={{ height: '400px', width: '100%' }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyDfl73x_3HQzjodsvtYpUk-y141AckFfDM' }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
      <Marker
        lat={54.0249078}
        lng={-6.8566822}
        name={'Marcon Tanks'}
        color="#1289c0"
      />
    </GoogleMapReact>
  </div>
)

export default irelandMap
